import { enumsData } from '@/mixins/enums'
import eventBus from '@/components/EventBus'
import { fetchTask } from '@/apis'

export const assignmentMethods = {
    data() {
        return {
            assignmentLoading: false
        }
    },
    mixins: [enumsData],
    methods: {
        isAssignmentOverdue(deadline) {
            const now = Date.now()
            const handinDate = new Date(deadline)
            const handinDateUTC = Date.UTC(
                handinDate.getUTCFullYear(),
                handinDate.getUTCMonth(),
                handinDate.getUTCDate(),
                handinDate.getUTCHours(),
                handinDate.getUTCMinutes(),
                handinDate.getUTCSeconds()
            )
            return now > handinDateUTC
        },
        submitAssignment: async function (longId, shortId, bid) {
            this.assignmentLoading = true
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Submitting assignment, please wait....',
                snackBarTimeout: 1500,
                snackBarIcon: 'info'
            })
            try {
                const { data } = await this.$axios.post(`/bundles/${bid}/submit`, {
                    iid: this.$route.params.iid,
                    handin_short_id: shortId,
                    handin_long_id: longId
                })
                this.assignmentLoading = false
                this.$store.dispatch('showGlobalDialog', {
                    dialogText: 'Assignment submission has started, see the status below',
                    dialogTitle: 'Assignment submission',
                    dialogAction: ['dismiss']
                })
                eventBus.$emit('global-dialog-task-started', true)
                const taskResult = await fetchTask(data.tkid)
                if (taskResult.isAxiosError) {
                    throw new Error(taskResult.response.data.reason)
                } else if (taskResult.data.status === this.taskStatusTypes.COMPLETED.value) {
                    eventBus.$emit('global-dialog-task-finished', true)
                    this.$store.dispatch('spaceStore/fetchSpaceBundles', this.$route.params.sid)
                } else {
                    eventBus.$emit('global-dialog-task-finished', taskResult)
                }
            } catch (error) {
                eventBus.$emit('global-dialog-task-finished', error)
            }
        },
        gotoAssignment(bid) {
            this.$store.dispatch('snapshotStore/setPathArray', [''])
            this.$router.push({
                name: 'assignment',
                params: { bid }
            })
        }
    }
}
